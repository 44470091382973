import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-20213b0e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "speaker-config-component"
};
var _hoisted_2 = {
  key: 0,
  style: {
    "width": "100%",
    "height": "100%"
  }
};
var _hoisted_3 = {
  "class": "desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.uiResourceConfig.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.uiResourceConfig, function (config) {
    return _openBlock(), _createElementBlock("div", {
      key: config.label
    }, [_createElementVNode("div", _hoisted_3, _toDisplayString(config.label), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(config.actions, function (item) {
      return _openBlock(), _createElementBlock(_Fragment, null, [item.widget_type === 'switch' ? (_openBlock(), _createBlock($setup["PsdkUiResourceSwitchItem"], {
        drone: $props.drone,
        data: item,
        key: item.widget_index,
        style: {
          "margin-bottom": ".5rem"
        }
      }, null, 8 /* PROPS */, ["drone", "data"])) : _createCommentVNode("v-if", true), item.widget_type === 'scale' ? (_openBlock(), _createBlock($setup["PsdkUiResourceScaleItem"], {
        drone: $props.drone,
        data: item,
        key: item.widget_index,
        style: {
          "margin-bottom": ".5rem"
        }
      }, null, 8 /* PROPS */, ["drone", "data"])) : _createCommentVNode("v-if", true), item.widget_type === 'list' ? (_openBlock(), _createBlock($setup["PsdkUiResourceListItem"], {
        drone: $props.drone,
        data: item,
        key: item.widget_index,
        style: {
          "margin-bottom": ".5rem"
        }
      }, null, 8 /* PROPS */, ["drone", "data"])) : _createCommentVNode("v-if", true), item.widget_type === 'button' ? (_openBlock(), _createBlock($setup["PsdkUiResourceButtonItem"], {
        drone: $props.drone,
        data: item,
        key: item.widget_index,
        style: {
          "margin-bottom": ".5rem"
        }
      }, null, 8 /* PROPS */, ["drone", "data"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
    }), 256 /* UNKEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createBlock($setup["DeepLoading"], {
    key: 1
  }))]);
}