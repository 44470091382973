import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7772f55c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "psdk-ui-resource-button-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_button, {
    size: "small",
    onClick: $setup.handleChange
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.widgetName) + "(" + _toDisplayString($setup.widgetIndex) + ") ", 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]);
}