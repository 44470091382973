import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2e2aa1c9"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "text-play"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
    type: "textarea",
    modelValue: $setup.textPlay,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.textPlay = $event;
    }),
    maxlength: "200",
    "show-word-limit": "",
    placeholder: "请输入文字。。。",
    "class": "textarea"
  }, null, 8 /* PROPS */, ["modelValue"]), _createVNode($setup["DeepButton"], {
    name: "notes",
    "class": "button",
    onClick: $setup.saveText
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("保存")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepButton"], {
    name: "notes",
    "class": "button",
    onClick: $setup.cancel,
    type: "primary"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("取消")];
    }),
    _: 1 /* STABLE */
  })]);
}