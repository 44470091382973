import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-41443fe3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "flight-control-left"
};
var _hoisted_2 = {
  key: 1,
  "class": "divider"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.aiOpen ? (_openBlock(), _createBlock($setup["AiPlayerComponent"], {
    key: 0,
    drone: $setup.currentDrone
  }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true), $setup.aiOpen ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("v-if", true), _createVNode($setup["DrawInMapComponent"]), _createVNode($setup["InfoDebug"])]);
}