import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "display": "none"
  },
  ref: "audioRef",
  controls: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("audio", _hoisted_1, null, 512 /* NEED_PATCH */);
}