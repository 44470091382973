import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d6a9b920"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "speaker-component"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "icons"
};
var _hoisted_4 = {
  style: {
    "flex": "1"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h4", null, _toDisplayString($setup.titleDesc), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tooltip, {
    placement: "top",
    content: "上传音频"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $setup.handleMakefileType('upload');
        }),
        name: "wayline-download",
        "class": _normalizeClass(["icon", $setup.selectedAction === 'upload' ? 'icon-selected' : ''])
      }, null, 8 /* PROPS */, ["class"])];
    }),
    _: 1 /* STABLE */
  }), $setup.audioSupport ? (_openBlock(), _createBlock(_component_el_tooltip, {
    key: 0,
    placement: "top",
    content: "录音"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $setup.handleMakefileType('record');
        }),
        name: "record",
        "class": _normalizeClass(["icon", $setup.selectedAction === 'record' ? 'icon-selected' : ''])
      }, null, 8 /* PROPS */, ["class"])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_el_tooltip, {
    placement: "top",
    content: "文字转语音"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $setup.handleMakefileType('tts');
        }),
        name: "upload-record",
        "class": _normalizeClass(["icon", $setup.selectedAction === 'tts' ? 'icon-selected' : ''])
      }, null, 8 /* PROPS */, ["class"])];
    }),
    _: 1 /* STABLE */
  })])]), _createElementVNode("div", _hoisted_4, [$setup.selectedAction === 'upload' ? (_openBlock(), _createBlock($setup["PsdkFileUploader"], {
    key: 0,
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"])) : $setup.selectedAction === 'record' ? (_openBlock(), _createBlock($setup["PsdKFileRecorder"], {
    key: 1,
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"])) : $setup.selectedAction === 'tts' ? (_openBlock(), _createBlock($setup["PsdkFileTtsMaker"], {
    key: 2,
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"])) : (_openBlock(), _createBlock($setup["SpeakerFileComponent"], {
    key: 3,
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"]))]), _createVNode($setup["SpeakerVideoComponent"], {
    drone: $props.drone
  }, null, 8 /* PROPS */, ["drone"])]);
}