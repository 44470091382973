import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["CesiumMap"], {
    ref: "bigemapRef",
    options: {
      selectionIndicator: true
    },
    navigation: false
  }, {
    scrim: _withCtx(function () {
      return [_createVNode($setup["GeoSearchComponent"], {
        onFlyTo: $setup.handleFlyTo
      })];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */);
}