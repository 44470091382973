import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-731283a6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "record-upload"
};
var _hoisted_2 = {
  "class": "container"
};
var _hoisted_3 = {
  "class": "handle-upload"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_progress = _resolveComponent("el-progress");
  var _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_table, {
    data: $setup.files,
    height: "100%",
    size: "small"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        prop: "file.name",
        label: "name"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "percent",
        label: "progress"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode(_component_el_progress, {
            style: {
              "width": "100%"
            },
            percentage: scope.row.percent,
            status: scope.row.status === 1 ? 'success' : scope.row.status === 2 ? 'exception' : ''
          }, null, 8 /* PROPS */, ["percentage", "status"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        label: "status",
        align: "center"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("span", null, _toDisplayString(scope.row.status === 0 ? '待上传' : scope.row.status === 1 ? '已上传' : '上传失败'), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        label: "action",
        align: "center"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode($setup["DeepButton"], {
            style: {
              "height": "1rem",
              "font-size": ".85rem !important"
            },
            disabled: scope.row.status === 1,
            type: "danger",
            size: "small",
            onClick: function onClick($event) {
              return $setup.handleRemove(scope.row.file);
            }
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("移除")];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])]), _createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepButton"], {
    "class": "button",
    ref: "uploadRef",
    size: "small"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("导入音频文件")];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */), _createVNode($setup["DeepButton"], {
    "class": "button",
    size: "small",
    onClick: $setup.handleUploads
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("上传")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["DeepButton"], {
    "class": "button",
    size: "small",
    onClick: $setup.handleClose
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("关闭")];
    }),
    _: 1 /* STABLE */
  })])]);
}