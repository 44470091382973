import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-913084de"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "psdk-component"
};
var _hoisted_2 = {
  style: {
    "padding": "1rem 1rem 0 1rem",
    "color": "white",
    "font-weight": "bold"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode("  <ThemeDropdown  placement=\"top\"  v-if=\"currentPsdkDevice\">"), _createCommentVNode("    <template #default=\"{show}\">"), _createCommentVNode("      <el-tooltip placement=\"bottom\" :content=\"'喊话器'\">"), _createCommentVNode("        <DeepSvgIcon name=\"svg-psdk\"  :class=\"{'deep-svg-icon-selected':show}\"></DeepSvgIcon>"), _createCommentVNode("      </el-tooltip>"), _createCommentVNode("    </template>"), _createCommentVNode("    <template #dropdown>"), $setup.drawer ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($setup.psdkName), 1 /* TEXT */), _createVNode(_component_el_tabs, {
    modelValue: $setup.activeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.activeName = $event;
    }),
    "class": "demo-tabs"
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.editableTabs, function (item, index) {
        return _createVNode(_component_el_tab_pane, {
          lazy: "",
          key: item.key,
          label: item.title,
          name: item.key
        }, {
          "default": _withCtx(function () {
            return [$setup.currentDrone ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
              key: 0,
              drone: $setup.currentDrone
            }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "name"]);
      }), 64 /* STABLE_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])])) : _createCommentVNode("v-if", true), _createCommentVNode("    </template>"), _createCommentVNode("  </ThemeDropdown>")], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}