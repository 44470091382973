import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2df1dc23"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "joystick-layout",
  ref: "touchViewRef"
};
var _hoisted_2 = {
  style: {
    "flex": "1"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepJoystick"], {
    "class": "joystick",
    ballMoveRadius: 70,
    onOnJoyStickUpdate: $setup.handleUpdate
  }), (_openBlock(), _createElementBlock("svg", _hoisted_2)), _createVNode($setup["DeepJoystick"], {
    "class": "joystick",
    ballMoveRadius: 70,
    onOnJoyStickUpdate: $setup.handleUpdate2
  })], 512 /* NEED_PATCH */);
}